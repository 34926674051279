<template>
  <div class="main-div">
    <PageBack />
    <div class="common_act">
      <div class="act_top">
        <div class="regist">
          <div class="regist_title">3s免费注册领取赠金</div>
          <div class="regist_body">
            <div class="bonus">
              <div class="bonus_title">赠金</div>
              <div class="bonus_num"><span>288</span>美元</div>
              <div class="bonus_con">新用户免费注册立即到账<br />可根据交易金额提现</div>
            </div>
            <div class="experience">
              <div class="bonus_title">体验金</div>
              <div class="bonus_num"><span>10000</span>美元</div>
              <div class="bonus_con">新用户免费注册立即到账<br />免费体验交易</div>
            </div>
          </div>
        </div>

        <div class="regbg">
          <div class="regist_title">激活本金翻倍</div>
          <div class="regist_body">
            <div class="bonus_num">本金<span>翻倍</span></div>
            <div class="bonus_con">100%全额返赠，首次激活72小时内，充多少送多少赠金</div>
          </div>
        </div>

        <div class="regist three_con">
          <div class="regist_title title3">现金红包，咨询客服领取</div>
          <div class="regist_body content3">
          </div>
          <div class="remark">注：现金红包可以直接提现，一个账户仅可领取一次</div>
        </div>
        <div class="buttom_con">
          <div class="custerm"><button class="custerm_buttom" @click="handleServe">咨询客服</button></div>
          <div class="register"><button class="reg_buttom" @click="ShowRegister"></button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBack from "@/components/pageBack.vue";
import { serve } from "@/mixins/serve.js";
export default {
  name: "ActivityPage",
  mixins: [serve],
  components: {
    PageBack,
  },
  data() {
    return {};
  },
  computed: {
    isShowRegister() {
      return this.$store.state.common.isShowRegister;
    },
  },
  methods: {
    ShowRegister() {
      if (!this.isShowRegister) {
        this.$store.commit("common/setIsShowRegist", true);
      }
    },
    handleServe() {
      this.doServe();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/activity.scss";
</style>
