<template>
  <div class="back-arrow is-show-small" @click="handleBack">
    <i></i>
  </div>
</template>

<script>
export default {
  name: "pageBack",
  data() {
    return {};
  },
  methods: {
    handleBack() {
      this.$router.goBack();
    },
  },
};
</script>
